import * as React from 'react';
import MainLayout from '../layouts/main-layout/main-layout';
import { graphql } from 'gatsby';
import ParsedContent from '../components/parsed-content/parsed-content';
import LogoSlider from '../components/logo-slider/logo-slider';

import * as styles from '../styles/pages/about-us.module.scss';

const AboutUsPage = ({ data }) => {
	return (
		<MainLayout
			seo={{
				title: 'O Nás',
				description: data?.api?.seo?.siteDescription,
			}}
		>
			<section className={styles.aboutCompanySection}>
				<h1>O Nás</h1>

				<ParsedContent html={data?.api?.aboutUs?.aboutUsText} />

				{data?.api?.aboutUs?.clientLogos?.length > 0 && (
					<>
						<h2>Naši klienti</h2>

						<LogoSlider logos={data?.api?.aboutUs?.clientLogos} />
					</>
				)}
			</section>
		</MainLayout>
	);
};

export const query = graphql`
	query {
		api {
			seo: seoSingleton {
				siteDescription
			}

			aboutUs: aboutUsSingleton {
				aboutUsText
				clientLogos {
					path
					meta
					file {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
						}
					}
				}
			}
		}
	}
`;

export default AboutUsPage;

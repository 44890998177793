import PropTypes from 'prop-types';
import * as React from 'react';
import { useRef } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './logo-slider.global.scss';
import * as styles from './logo-slider.module.scss';

const LogoSlider = ({ className, logos }) => {
	const swiperRef = useRef(null);

	return (
		<Swiper
			ref={swiperRef}
			slidesPerView="auto"
			loop={true}
			allowTouchMove={false}
			spaceBetween={40}
			centeredSlides={true}
			speed={2430}
			autoplay={{
				delay: 1200,
			}}
			className={classNames({
				'logo-slider': true,
				[styles.slider]: true,
				[className]: className,
			})}
		>
			{logos?.map((logo, i) => {
				const logoImage = getImage(logo?.file);

				return (
					<SwiperSlide
						key={`logoSlide${i}`}
						className={styles.slide}
						style={{
							aspectRatio: (logoImage?.width / logoImage?.height)?.toString(),
						}}
					>
						<a href={logo?.meta?.link} title={logo?.meta?.title} target="_blank" rel="noreferrer">
							<GatsbyImage
								alt={logo?.meta?.alt ?? 'Logo'}
								image={getImage(logoImage)}
								className={styles.image}
								objectFit="contain"
								objectPosition="center"
							/>
						</a>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

LogoSlider.defaultProps = {
	logos: [],
};

LogoSlider.propTypes = {
	className: PropTypes.string,
	logos: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string,
			meta: PropTypes.any,
			file: PropTypes.any,
		})
	),
};

export default LogoSlider;
